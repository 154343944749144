import { GetStaticPaths, GetStaticProps } from "next";
import { FunctionComponent } from "react";
import { SuperHero } from "@bluebottlecoffee/design-system";
import sanityClient from "../../../lib/sanity-client";
import renderModule from "../../../lib/render-module";
import { toSuperHeroProps } from "../../../lib/transformers/super-hero";
import { LayoutWrapper } from "../../../components/LayoutWrapper";
import {
  getDefaultStaticPaths,
  getDefaultStaticProps,
} from "../../../lib/utils";
import { Homepage } from "../../../lib/sanity-schema";
import { BaseProps } from "../../../lib/sanity/shared";
import { filters } from "../../../lib/sanity/utils";

interface PageData extends BaseProps {
  homepage: Homepage;
}

export const getStaticProps: GetStaticProps<PageData> = async (context) => {
  const { props: defaultProps } = await getDefaultStaticProps(context);

  const homepage = await sanityClient().fetch<Homepage>(`*[
    ${filters.whereType("homepage")} &&
    ${filters.excludeDrafts}
  ][0]`);

  return {
    props: {
      homepage,
      ...defaultProps,
    },
  };
};

export const getStaticPaths: GetStaticPaths = getDefaultStaticPaths;

function validate(data: Homepage) {
  if (!data.title) {
    throw Error("Homepage must have a title.");
  }
}

const HomePage: FunctionComponent<PageData> = ({
  homepage,
  ...layoutWrapperProps
}) => {
  validate(homepage);
  const { region, lang } = layoutWrapperProps;

  return (
    <LayoutWrapper
      {...{
        ...layoutWrapperProps,
        pageTitle: homepage.title[lang],
        metaTags: homepage.metaTags,
      }}
      isTransparent
    >
      {homepage.hero?.length > 0 && (
        <SuperHero {...toSuperHeroProps(homepage.hero, region, lang)} />
      )}
      {homepage.modules?.length > 0 &&
        homepage.modules.map((m) => renderModule(m, region, lang))}
    </LayoutWrapper>
  );
};

export default HomePage;
